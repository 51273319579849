class linkBanner{
    pt=null;
    def:any = {
        point:".bannerPoint",
        time:4000,
        speed:500
    };
    oDiv:any;
    liList:any = [];
    startNum:number=0;
    maxNum:number = 1;
    startTime:number = 0;
    addTime:number = 0;
    speed:any = '';
    canChange:boolean = true;
    constructor(name:any,onj:object){
        this.oDiv = document.querySelector(name)
        this.def = Object.assign(this.def,onj);
        this.init();
    }
    init(){
        let _this = this;
       this.liList = this.oDiv.querySelectorAll("li");
       this.maxNum = this.liList.length;
       if(this.maxNum >1){
           for(let i = 1; i<=this.maxNum;i++){
               $(this.def.point).append(`<div class="pointItem">
                <p class="ptxt">${this.makeNum(i)}</p>
                <p class="lineTips"><span></span></p>
            </div>`);
           }
       }
       $(this.oDiv).find(this.def.point).find(".pointItem").eq(this.startNum).addClass("on").siblings().removeClass("on");
       $(this.oDiv).find("li").eq(this.startNum).addClass("on").siblings().removeClass("on");
       this.speed = {
           'transition':`all ${this.def.speed/1000}s ease-in-out`,
           '-webkit-transition':`all ${this.def.speed/1000}s ease-in-out`,
           '-moz-transition':`all ${this.def.speed/1000}s ease-in-out`,
           '-ms-transition':`all ${this.def.speed/1000}s ease-in-out`,
           '-o-transition':`all ${this.def.speed/1000}s ease-in-out`
       };
        this.startTime = new Date().getTime();
       //this.speed =
        this.runTime();
        $(this.oDiv).find(this.def.point).find(".pointItem").click(function () {
            if(!$(this).hasClass("on") && _this.canChange){
                var index = $(this).index();
                var num = -1;
                if(index < _this.startNum){
                    num = 1;
                }
                _this.changeLi(index,num);
            }
        })
    }
    runTime(){
        let _this = this;
        let time:any = new Date();
        this.addTime  += time - this.startTime;
        if(this.addTime >= this.def.time){
            this.addTime = 0;
            let num = this.startNum + 1 == this.maxNum ? 0 : this.startNum + 1;
            this.changeLi(num,-1);
            $(this.oDiv).find(this.def.point).find(".pointItem").eq(this.startNum).find("span").height("100%")
        }else{
            this.startTime = time;
            let p = parseInt((this.addTime/this.def.time)*100);
            $(this.oDiv).find(this.def.point).find(".pointItem").eq(this.startNum).find("span").height(p+"%");
            this.pt = setTimeout(()=>{
                _this.runTime();
            },10);
        }

    }
    changeLi(index,type){
        if(!this.canChange){
            return false;
        }
        this.canChange = false;
        clearTimeout(this.pt);
        let _this = this;
        var type = type || 1;
        var w =   $(this.oDiv).find("li").width();
        var moveX = w*type*0.3+"px";
        //$(this.oDiv).find(this.def.point).find(".pointItem").removeClass("on");
        let obj = rtunrnNum(moveX);
        obj = Object.assign(obj,this.speed);
        // let obj = {
        //     'transition':`all ${this.def.speed/1000} ease-in-out`,
        //     '-webkit-transition':`all ${this.def.speed/1000} ease-in-out`,
        //     '-moz-transition':`all ${this.def.speed/1000} ease-in-out`,
        //     '-ms-transition':`all ${this.def.speed/1000} ease-in-out`,
        //     '-o-transition':`all ${this.def.speed/1000} ease-in-out`
        // };
        obj['opacity'] = 0;
       // console.log(obj);
        $(this.oDiv).find("li").eq(index).css({"opacity":1,"z-index":"5"});
        $(this.oDiv).find("li").eq(this.startNum).css(obj);
        setTimeout(function () {
            _this.startNum = index;
            $(_this.oDiv).find(_this.def.point).find(".pointItem").eq(_this.startNum).addClass("on").siblings().removeClass("on");
            $(_this.oDiv).find("li").eq(_this.startNum).addClass("on").siblings().removeClass("on");
            $(_this.oDiv).find("li").css({
                "transform":"",
                "transition":"",
                "-webkit-transform":"",
                "-webkit-transition":"",
                "-moz-transform":"",
                "-moz-transition":"",
                "-ms-transform":"",
                "-ms-transition":"",
                "-o-transform":"",
                "-o-transition":"",
                "opacity":"",
                "z-index":""
            })
            _this.startTime = new Date().getTime();
            _this.runTime();
            _this.canChange = true;
            _this.addTime = 0;
        },this.def.speed);
    }
    makeNum(num){
        let txt = num < 10 ? '0'+num : num;
        return txt;
    }

}
